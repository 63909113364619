import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1
                  style={{
                    background: "linear-gradient(to right, #feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5)", // Instagram-like gradient
                    WebkitBackgroundClip: "text", // Apply the gradient to the text
                    color: "transparent", // Make the text transparent to show the gradient
                    fontSize: "48px", // Adjust size as needed
                    fontWeight: "bold",
                    textAlign: "center", // Align the text to center
                  }}
                >
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <h3 style={{
                  background: "linear-gradient(45deg, #ff6f61, #ff9a76, #ffc93c, #6fe7dd, #348ac7)", // New multicolor gradient
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                }}>
                  Available on Playstore
                  <span></span>
                </h3>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="https://play.google.com/store/apps/details?id=com.ganeshshiva.goglobal&hl=en_IN"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Download Now
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
