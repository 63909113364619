import { useState } from "react";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct mailto URL, sending to OmiChat@hotmail.com with subject as name and body as the message.
    const mailtoLink = `mailto:OmiChat@hotmail.com?subject=${encodeURIComponent(
      name
    )}&body=${encodeURIComponent(message)}&from=${encodeURIComponent(email)}`;

    // Open the default mail client with the constructed mailto link
    window.location.href = mailtoLink;

    clearState(); // Reset form
  };


  return (
    <div id="contact">
      <div >
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Subject"
                        required
                        onChange={handleChange}
                        value={name}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                        value={email}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                    value={message}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.location.href = "mailto:OmiChat@hotmail.com";
                }}
              >
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                OmiChat@hotmail.com
              </p>

            </div>
          </div>
        </div>
      </div>
      <footer id="footer" className="footer" style={{ backgroundColor: "black" }}>
        <div className="container text-center">
          <p>&copy; {2023} OmiChat. All rights reserved.</p>
          <ul className="footer-links">
            <li>
              <a href="https://docs.google.com/document/d/12HGJICDX-QbOC_-uc-lauoTVq1evKrtX/edit?usp=sharing&ouid=115019972987748360720&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};
