import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// Import your Dashboard component
import Dashboard from './Dashboard';

const AppAds = () => {
  return (
    <div>
      <pre>
        google.com, pub-3188753354228120, DIRECT, f08c47fec0942fa0
      </pre>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/app-ads.txt" element={<AppAds />} />
        <Route path="/*" element={<Dashboard />} />
      </Routes>
    </Router>
  );
};

export default App;
