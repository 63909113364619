import React from "react";
import './style.css';

export const DownloadPage = (pros) => {
    return (
        <>
            <div id="newsspec-19854-app" class="news-app-promo">
                <div class="news-app-promo-text">
                    <div class="news-app-promo-text__tagline">The best way to get Connect world and find New Peoples
                        </div><br />
                    <div class="news-app-promo-text__download">Download the OmiChat App.</div>
                </div>
                <div class="news-app-promo__section">
                    <div class="news-app-promo-subsection">
                        <img
                            className="news-app-promo__bbc-logo"
                            src={process.env.PUBLIC_URL + "/img/logo.png"}
                            width="106"
                            height="106"
                            style={{
                                borderRadius: "4%", // Makes it circular
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Adds a shadow effect
                                transition: "transform 0.2s", // Smooth transformation on hover
                            }}
                            onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")} // Enlarge on hover
                            onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")} // Reset size
                            alt="Logo"
                        />

                    </div>
                    <div class="news-app-promo-subsection">
                        <a class="news-app-promo-subsection--link news-app-promo-subsection--playstore" href="https://play.google.com/store/apps/details?id=com.ganeshshiva.goglobal&hl=en_US" target="_parent">
                            <img class="news-app-promo__play-store" src="//news.files.bbci.co.uk/include/newsspec/19854/assets/app-project-assets/google_play_store.svg" width="161" height="auto" border="0" />
                        </a>

                    </div>
                </div>
                <div class="news-app-promo__section">

                </div>
            </div>
        </>
    );
};
